<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black">Deals{{ search }}{{ app }}</h1>
      <div>
        <!-- <button class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/filter.svg" alt="Snow" />  
          </button> -->

        <button id="popover-button-bottom" href="#" tabindex="0" type="button" class="btn notofication-btn mr-1">
          <div>
            <img src="../../../assets/images/client/filter.svg" alt="Snow" />
          </div>
        </button>

        <b-popover ref="popover" target="popover-button-bottom" triggers="hover" placement="Left"
          class="custom-popover pop-btn">
          <div class="center custom-vs-select" style="border: none;width:5cm">
            <v-select class="" :searchable="false" shadow placeholder="Select" v-model="statusFilter" @input="getClientStore()" :clearable="false" :options="['All','Active','Inactive']"/>
          </div>
        </b-popover>

        <button type="button" v-b-tooltip.hover.v-primary title="Export CSV" @click="exportToCSV" variant="outline-primary"
          class="btn notofication-btn mr-1" >
          <img src="../../../assets/images/client/export.svg" alt="Snow" />
        </button>

        <button id="popover-button-variant" href="#" tabindex="0" type="button" class="btn notofication-btn mr-3  "
          :style="popoverShow ? 'background-color: black;' : ''"
          @click="onPopovcerOpen()">
          <div>
            <img
              v-if="!popoverShow"
              id="task1"
              src="../../../assets/images/client/task-square.svg"
              alt="Snow"
            />

            <img
            v-else
              id="task2"
              src="../../../assets/images/client/task-light.svg"
              alt="Snow"
            />
          </div>
        </button>

        <!-- <b-popover ref="popover" target="popover-button-variant" triggers="click" class="custom-popover"
          :show.sync="popoverShow">
          <button @click="onPopovcerOpen" class="notofication-btn" style="color: #408dff; margin: 5px" size="sm">
            Cancel
          </button>

          
          <button  @click="HandelClickMultipleDublicate()" type="button" class="btn notofication-btn" style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/copy.png" alt="Snow" />
          </button>
          <button  @click="HandelClickMultipleEnableDisable()" type="button" class="btn notofication-btn ml-1" style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/circle.svg" alt="Snow" />
          </button>
          <button type="button" class="btn notofication-btn"  @click="HandelClickMultipleDelete()"
            style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px">
            <img src="../../../assets/images/client/delete.png" alt="Snow" />
          </button>
        </b-popover> -->

        <div v-if="popoverShow" class="popover-manual">
          <div class="popover-inner">
            <button @click="onPopovcerOpen" class="notofication-btn" style="color: #408dff; margin: 5px" size="sm">
            Cancel
          </button>

          
          <button  @click="HandelClickMultipleDublicate()" type="button" class="btn notofication-btn" style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/copy.png" alt="Snow" />
          </button>
          <button  @click="HandelClickMultipleEnableDisable()" type="button" class="btn notofication-btn ml-1" style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/circle.svg" alt="Snow" />
          </button>
          <button type="button" class="btn notofication-btn"  @click="HandelClickMultipleDelete()"
            style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px">
            <img src="../../../assets/images/client/delete.png" alt="Snow" />
          </button>
        </div>
        <div class="popup-edge">
          </div>
        </div>
        <button type="button" class="btn btn-primary" @click="addDealClick" style="border-radius: 16px; padding: 16px" v-b-modal.modal>
          +&nbsp; Add Deal
        </button>

        <b-modal id="modal" ref="modal" size="lg" class="m-2 custom--model">
          <template #modal-header>
          <div style="width:50%;padding: 10px 20px;" class="form-row">
            <div class="form-group col-md-6">
              <h2>Add Deal</h2>
            </div>
            <div class="form-group col-md-6">
                <b-form-checkbox v-model="status" :checked="status" class="my-checkbox ml-1" name="check-button" switch>
                  <span class="switch-icon-left">
                    Active
                  </span>
                  <span class="switch-icon-right">
                    Inactive
                  </span>
                </b-form-checkbox>
              </div>
          </div>
            <b-button @click="closeModal" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <validation-observer ref="submitPrevent" #default="{ invalid }">
            <b-form @submit.prevent="submitForm">
          <div class="form-row">
              
              <div class="col-md-6">
                <b-form-group class="mb-2" label="Text En">
                  <validation-provider #default="{ errors }" name="Text En" rules="required">
                    <b-form-input v-model="textEn" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Text En" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                </div>
              <div class="col-md-6">
                <b-form-group class="mb-2" label="Text Ar">
                  <validation-provider #default="{ errors }" name="Text Ar" rules="required">
                    <b-form-input v-model="textAr" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Text Ar" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                </div>
              <div class="col-md-6">
                <b-form-group class="mb-2" label="Short Description En">
                  <validation-provider #default="{ errors }" name="Short Description En" rules="required">
                    <b-form-input v-model="shortDesEn" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Short Description En" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                </div>
              <div class="col-md-6">
                <b-form-group class="mb-2" label="Short Description Ar">
                  <validation-provider #default="{ errors }" name="Short Description Ar" rules="required">
                    <b-form-input v-model="shortDesAr" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Short Description Ar" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                </div>
              <div class="col-md-6">
                <b-form-group class="mb-2" label="Description En">
                  <validation-provider #default="{ errors }" name="Description En" rules="required">
                    <b-form-input v-model="descriptionEn" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Description En" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                </div>
              <div class="col-md-6">
                <b-form-group class="mb-2" label="Description Ar">
                  <validation-provider #default="{ errors }" name="Description Ar" rules="required">
                    <b-form-input v-model="descriptionAr" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Description Ar" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                </div>
            <div class="form-group col-md-12">
              <label class="h5" for="inputEmail4">Banner is mapped with</label>
              <VRadioGroup @change="radioChange" v-model="inlineRadio" inline>
                <div class="row m-0">
                  <div md="2" class="custom-radio d-flex col-md-4 mr-1" :style="{
                    border:
                      inlineRadio == 'Brand' ? '2px solid #3F8CFF' : '',
                  }">
                    <VRadio label="Brand" value="Brand" />
                  </div>
                  <div md="2" class="custom-radio col-md-4" :style="{
                    border: inlineRadio == 'Coupon' ? '2px solid #3F8CFF' : '',
                  }">
                    <VRadio label="Coupon" value="Coupon" />
                  </div>
                </div>
              </VRadioGroup>
            </div>
            
            <div class="col-md-12"  v-if="inlineRadio == 'Brand'">
            <b-form-group class="mb-2" label="Brand" >
              <validation-provider #default="{ errors }" name="Brand" rules="required">
                <v-select v-model="brand" :options="clientOption" @input="changeDrop('brand')" label="name_en" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            </div>
          
            <div class="col-md-12" v-if="inlineRadio == 'Coupon'">
            <b-form-group class="mb-2" label="Coupon">
              <validation-provider #default="{ errors }" name="Coupon" rules="required">
                <v-select v-model="coupon" :options="couponOption" @input="changeDrop('coupon')" label="label" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group class="mb-2" label="Custom Url">
                <validation-provider #default="{ errors }" name="Custom Url" rules="required">
                  <b-form-input v-model="customUrl" :state="errors.length > 0 ? false : null"
                    placeholder="Enter Custom Url" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </div>

        <div class="col-md-6">
          <b-form-group class="mb-2" label="Sequence">
            <validation-provider #default="{ errors }" name="Sequence" rules="required|integer">
              <b-form-input v-model="sequence" :state="errors.length > 0 ? false : null"
                placeholder="Enter Sequence" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          </div>
            <div class="col-md-6">
              <b-form-group class="mb-2" label="Image Url">
                <validation-provider #default="{ errors }" name="Image Url" rules="required">
                  <b-form-input v-model="imageEn" :state="errors.length > 0 ? false : null"
                    placeholder="Enter Image Url" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </div>
            <div class="col-md-6">
              <b-form-group class="mb-2" label="Image Url Ar">
                <validation-provider #default="{ errors }" name="Image Url Ar" rules="required">
                  <b-form-input v-model="imageAr" :state="errors.length > 0 ? false : null"
                    placeholder="Enter Image Url Ar" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </div>
            
            <div class="col-md-6">
                <b-form-group class="mb-2" label="Tag Text En">
                  <validation-provider #default="{ errors }" name="Tag Text En" rules="required">
                    <b-form-input v-model="TagTextEn" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Tag Text En" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                </div>
              <div class="col-md-6">
                <b-form-group class="mb-2" label="Tag Text Ar">
                  <validation-provider #default="{ errors }" name="Tag Text Ar" rules="required">
                    <b-form-input v-model="TagTextAr" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Tag Text Ar" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                </div>
            <div class="col-md-6">
              <b-form-group class="mb-2" label="Tag Name">
                <validation-provider #default="{ errors }" name="Tag Name" rules="required">
                  <v-select v-model="tagName" class=""  label="name" :options="['New offers','Best deals']"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group class="mb-2" label="Background Color">
                <validation-provider #default="{ errors }" name="Background Color" rules="required">
                  <b-form-input v-model="bgColor" :state="errors.length > 0 ? false : null"
                    placeholder="Enter Background Color" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              Note : Please add hex code of background color.
              </div>
              </div>
          <div class="modal-footer1">
            <button type="button" class="btn btn-default mr-1" style="border: 1px solid black" data-dismiss="modal">
              Add Another Deal
            </button>
            <button type="button"  @click="AddDeal"  class="btn btn-primary" :disabled="invalid">{{edit ? 'Update' :'Add'}}</button>
          </div>
            </b-form>
          </validation-observer>
          <!-- <div>
        <div style="display:flex;">
          <button type="button" class="btn btn-secondary">Cancel</button>
          <button type="button" class="btn btn-primary">OK</button>
        </div>
      </div> -->
        </b-modal>
        
        <b-modal id="showmodal" ref="showmodal" size="lg" class="m-2 custom--model">
          <template #modal-header>
            <div class="d-flex align-items-center">
                <div class="ml-1 mr-2">
                  <b-img v-if="checkURL(showItem.image)" style="width:5.5cm"  :src="showItem.image"
                    alt="Circle image" class="d-inline-block roundedImage" />
                  <b-img v-else style="width:5.5cm"
                    :src="require('@/assets/images/no-pic.png')" alt="Circle image"
                    class="d-inline-block roundedImage" />
                </div>
                <div>
                  <div class="font-weight-bolder h4 text-black mb-half">{{ showItem.text }}<span v-if="showItem.text_ar">-{{ showItem.text_ar   }}</span>
                  </div>
                  <a :href="showItem.redirection_url" target="_blank">{{ showItem.redirection_url ? showItem.redirection_url.slice(0, 100) : '' }}{{ showItem.redirection_url ? showItem.redirection_url.length
                    > 100 ? '...' : '' : '' }}</a>
                    <div class="font-weight-bolder text-black">
                        <span v-if="showItem.is_active" class="btn btn-outline-success-view btn-sm">Active</span>
                        <span v-else class="btn btn-outline-danger-view btn-sm">Inactive</span>
                    </div>
                </div>
              </div>
            <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <b-row class="p-1">
            <b-col md="12" class="p-0" v-if="showItem.coupon_id">
              <div class="mb-half">Coupon code</div>
                  <span class="font-weight-bolder h1" style="color: #3f8cff">{{ showItem.coupon_id.code }}</span>
                  </b-col>
            <b-col md="12" class="p-0 mt-1">
              <div>
                <div class="mb-half">Short Description En</div>
                <div class="font-weight-bolder h5 text-black">{{showItem.short_description}}</div>
              </div>
            </b-col>
            <b-col md="12" class="p-0 mt-1">
              <div>
                <div class="mb-half">Short Description Ar</div>
                <div class="font-weight-bolder h5 text-black">{{showItem.short_description_ar}}</div>
              </div>
            </b-col>
            <b-col md="12" class="p-0 mt-1">
              <div>
                <div class="mb-half">Description En</div>
                <div class="font-weight-bolder h4 text-black">{{showItem.description}}</div>
              </div>
            </b-col>
            <b-col md="12" class="p-0 mt-1">
              <div>
                <div class="mb-half">Description Ar</div>
                <div class="font-weight-bolder h4 text-black">{{showItem.description_ar}}</div>
              </div>
            </b-col>
            <b-col md="4" class="p-0 mt-1">
              <div>
                <div class="mb-half">Banner Mapped With</div>
                <div class="font-weight-bolder h4 text-black">{{showItem.client_id ? 'Brand' : 'Coupon'}}</div>
              </div>
            </b-col>
           
            <b-col md="4" class="p-0 mt-1">
              <div>
                <div class="mb-half">Sequence</div>
                <div class="font-weight-bolder h4 text-black">{{showItem.sequence}}</div>
              </div>
            </b-col>
            <b-col md="4" class="p-0 mt-1">
              <div>
                <div class="mb-half">Tag Name</div>
                <div class="font-weight-bolder h4 text-black">{{showItem.tag_name}}</div>
              </div>
            </b-col>
            <b-col md="4" class="p-0 mt-1">
              <div>
                <div class="mb-half">Tag Text En</div>
                <div class="font-weight-bolder h4 text-black">{{showItem.button_text}}</div>
              </div>
            </b-col> <b-col md="4" class="p-0 mt-1">
              <div>
                <div class="mb-half">Tag Text Ar</div>
                <div class="font-weight-bolder h4 text-black">{{showItem.button_text_ar}}</div>
              </div>
            </b-col>

            <b-col md="4" class="p-0 mt-1">
              <div>
                <div class="mb-half">Tag</div>
                <div class="font-weight-bolder h4 text-black">{{showItem.tag}}</div>
              </div>
            </b-col>
            <b-col md="4" v-if="showItem.client_id" class="p-0 mt-1">
              <div>
                <div class="mb-half">Brand</div>
                <div class="font-weight-bolder h4 text-black">{{showItem.client_id.name_en}}</div>
              </div>
            </b-col>
            
            <b-col md="4" class="p-0 mt-1">
              <div>
                <div class="mb-half">Background Color</div>
                <div class="font-weight-bolder h5 text-black">{{showItem.bg_color}}</div>
              </div>
            </b-col>
            <b-col md="12" v-if="showItem.coupon_id" class="p-0 mt-1">
              <div>
                <div class="mb-half">Coupon</div>
                <div class="font-weight-bolder h4 text-black">{{showItem.coupon_id.id}}-{{ showItem.coupon_id.code }}-{{ showItem.coupon_id.app_name }}-{{ showItem.coupon_id.title_en }}</div>
              </div>
            </b-col>
            <b-col md="12" class="p-0 mt-1">
              <div>
                <div class="mb-half">Image Url</div>
                <div class="font-weight-bolder h5 text-black">{{showItem.image}}</div>
              </div>
            </b-col>
            <b-col md="12" class="p-0 mt-1">
              <div>
                <div class="mb-half">Image Url Ar</div>
                <div class="font-weight-bolder h5 text-black">{{showItem.image_ar}}</div>
              </div>
            </b-col>
            </b-row>
          </b-modal>
      </div>
    </div>

    
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="dealsData.length == 0">
      <img style="width:13cm; height:13cm" :src="host+'/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
      <draggable
      @change="draggableChange($event)"
        v-model="dealsData"
        handle=".draggable-task-handle"
        tag="ul"
        class="todo-task-list media-list draggable-task-handle d-inline"
      >
        <b-card
          no-body
          class="card-revenue-budget dash-total-card mb-2 drag-el"
          v-for="(item, index) in dealsData"
          @dblclick="handelShow(item)"
          :key="index"
          >
          <b-row class="p-1">
            <b-col md="3">
              <div class="d-flex align-items-center">
                <div>
                  <b-form-checkbox
                    v-if="popoverShow"
                    v-model="checkBoxIds[item.id]"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                    @change="CheckBoxHandelClick(item.id)"
                  />
                </div>
                <div class="ml-1 mr-2">
                  <b-img v-if="checkURL(item.image)" :style="popoverShow?'width:5cm;':'width:5.5cm'"  :src="item.image"
                    alt="Circle image" class="d-inline-block roundedImage" />
                  <b-img v-else :style="popoverShow?'width:5cm':'width:5.5cm'"  
                    :src="require('@/assets/images/no-pic.png')" alt="Circle image"
                    class="d-inline-block roundedImage" />
                </div>
                <!-- <div>
                  <div class="font-weight-bolder text-black mb-half">
                    {{ item.name }}
                  </div>
                  <div>www.noon.com</div>
                </div> -->
              </div>
            </b-col>
            <b-col md="9">
                <b-row>
                    <b-col md="10">
                        <div>
                        <div class="font-weight-bolder text-black mb-half">
                          {{item.text}}<br/>
                            {{item.description}}
                        </div>
                        <a :href="item.redirection_url" target="_blank">{{ item.redirection_url.slice(0, 40) }}{{ item.redirection_url.length
                    > 40 ? '...' : '' }}</a>
                    </div>
                    </b-col>
                    
                    <b-col md="2" class="d-flex align-items-center justify-content-center text-black">
                      <div
                      :id="'popover-button-variant'+item.id"
                      href="#"
                        style="background: #f4f9fd; padding: 8px; border-radius: 16px"
                      >
                        <feather-icon icon="MoreVerticalIcon" size="25" />
                      </div>
            </b-col>
                </b-row>
                  <b-popover
                  :target="'popover-button-variant'+item.id"
                  triggers="hover"
                  class="custom-popover"
                >
                  <!-- <button
                    class="notofication-btn"
                    style="color: #408dff; margin: 5px"
                    size="sm"
                  >
                    Cancel
                  </button> -->
          <button
            type="button"
            class="btn notofication-btn ml-1"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="handelShow(item)"
            style="background: rgb(125 133 146 / 13%); margin: 8px"
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
          </button>
                  <button
                    type="button"
                    class="btn notofication-btn ml-1"
                    @click="handelEdit(item.id)"
                    v-b-modal.modal
                    style="background: rgb(63 140 254 / 13%); margin: 8px"
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3f8cfe" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                  </svg>
                  </button>
                  <button
                    type="button"
                    class="btn notofication-btn"
                    @click="handelDelete(item.id)"
                    style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px"
                  >
                    <img src="../../../assets/images/client/delete.png" alt="Snow" />
                  </button>
                </b-popover>
                <b-row>
                    <b-col md="3" class="d-flex align-items-center">
                        <div>
                            <div class="mb-half">Tag</div>
                            <div class="font-weight-bolder text-black">{{item.tag}}</div>
                        </div>
                    </b-col>
                    <b-col md="3">
                        <div>
                            <div class="mb-half">Coupon Code</div>
                            <div class="font-weight-bolder text-black">
                            <span class="font-weight-bolder" style="color: #3f8cff">{{item.coupon_code}}</span>
                            </div>
                        </div>
                    </b-col>
                    <b-col md="3" class="d-flex align-items-center">
                        <div>
                            <div class="mb-half">Sequence</div>
                            <div class="font-weight-bolder text-black">{{ item.sequence }}</div>
                        </div>
                    </b-col>
                    <b-col md="3">
                        <div>
                            <div class="mb-half">Status</div>
                            <div class="font-weight-bolder text-black">
                                <span v-if="item.status" class="btn btn-outline-success btn-sm">Active</span>
                                <span v-else class="btn btn-outline-danger btn-sm">Inactive</span>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
          </b-row>
        </b-card>
      </draggable>
      <div class="no-results" :class="{ show: !dealsData.length }"></div>
    </div>

    <button v-if="!isLoading && dealsData.length" type="button" class="w-auto btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }}-{{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control paginationInput" :style="'width:' + ((pageNo.toString().length*10)) + 'px'" @input="pagechange" type="number"/>
        <feather-icon icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
        
      </div>
    </button>

    <button v-if="popoverShow && !isLoading" id="seeall-btn" type="button" class="w-auto btn see-btn" @click="selectAllClick()">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ selectAllLabel }}</p>
      </div>
    </button>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import { BImg } from 'bootstrap-vue'
import json2csv from 'json2csv';

import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import notfoundsvg from '../../../../public/NoDataFound.svg'
import { mapState } from 'vuex';
export default {
  components: {
    draggable,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor, BImg,
    ToastificationContent
  },
  data() {
    return {
      notfoundsvg,
      required, integer,
      inlineRadio: "Coupon",
      advetiserRadio: "Itcan",
      value: "1",
      mainProps: {
        width: 70,
        height: 70,
        class: 'm1',
      },
      data: {},
      filter: 'All',
      selectAllLabel: 'Select All',
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      clientOption: [],
      couponOption:[],
      dealsData: [],
      showItem:{},
      dealsData1: [],
      textEn:'',
      textAr:'',
      shortDesEn:'',
      shortDesAr:'',
      descriptionEn:'',
      descriptionAr:'',
      inlineRadio:'Brand',
      brand:{},
      coupon:{},
      customUrl:'',
      tagName:'',
      TagTextEn:'',
      TagTextAr:'',
      bgColor:'',
      imageAr:'',
      imageEn:'',
      popoverShow: false,
      edit: false,
      pageNo: 1,
      paginationStart: 0,
      isLoading: true,
      paginationEnd: 10,
      paginationLength: 0,
      statusFilter:"All",
      status: '',
      sequence: '',
      checkBoxIds: {},
      selectAll: false,
    };
  },
  mounted() {
    this.getdropData();
    // this.getClientStore();
  },
  // watch: {
  //  'sidebar': {
  //    handler: 'checkIsValid',
  //    immediate: true, 
  //  },
  // '$store.state.application': {
  //   handler: 'checkIsValid',
  //   immediate: true,
  // },
  // },
  computed: {
      ...mapState(['sidebar']),
    search() {
      // let data = []
      let search = this.$store.state.searchData
      this.getSearch(search);
    },
    app() {
      this.$store.state.searchData = ''
      if(this.$store.state.application){
        this.getSearch('');
      }
    },
  },
  methods: { 
    checkIsValid() {
      if (this.sidebar) {
        let is_valid = this.sidebar.some(data => data.name == 'Deals');
        if (!is_valid) {
          this.$router.push('/');
        }
      }
    },
    solution2(message) {
       try {
        this.$copyText(message, this.$refs.showmodal);
        this.onCopy();
      } catch (error) {
        this.errorMessage = 'Error copying text: ' + error.message;
        this.onError();
      }
    },
    onCopy() {
      this.$refs.showmodal.hide()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Code copied',
          icon: 'CheckIcon',
        },
      })
    },
    onError() {
      this.$toast({ 
        component: ToastificationContent,
        props: {
          title: 'Failed to copy Code!',
          icon: 'XIcon',
        },
      })
    },
    handelShow(item){
      this.$refs.showmodal.show()
      this.popoverShow=false
      this.showItem = item
    },
    draggableChange(e) {
      let search = this.$store.state.searchData
      let data = {
        newIndex: this.dealsData1[e.moved.newIndex].sequence,
        oldIndex: this.dealsData1[e.moved.oldIndex].sequence,
        pageno: this.pageNo,
        statusFilter: this.statusFilter
      }
      if (search) { data = { ...data, search: search } }
      this.$http.post(`${this.baseURL}/deals/sequence-change`, data,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.dealsData = []
          this.dealsData1 = []
          this.dealsData = res.data.data
          this.paginationLength = res.data.totaldata
          this.dealsData1 = res.data.data
        })
    },
    addDealClick(){
      this.textEn='',
      this.textAr='',
      this.shortDesEn='',
      this.shortDesAr='',
      this.descriptionEn='',
      this.descriptionAr='',
      this.inlineRadio='Brand',
      this.brand={},
      this.coupon={},
      this.sequence = 0
      this.sequence1 = 0
      this.customUrl='',
      this.tagName='',
      this.TagTextEn='',
      this.TagTextAr='',
      this.bgColor='',
      this.imageAr='',
      this.imageEn='',
      this.popoverShow=false
      this.status = false,
      this.$http.post(`${this.baseURL}/deals/get-sequence`).then((json) => {
        this.sequence = json.data.sequence
        this.sequence1 = json.data.sequence
      })
    },
    checkURL(url) {
      const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
      return pattern.test(url ? url.replace(/\s/g,'%20') : url);
    },
    radioChange(){
      this.coupon = {}
      this.banner = {}
    },
    getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/deals/search`, {statusFilter:this.statusFilter, search: search, pageno: this.pageNo, app_id: this.$store.state.application.id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.dealsData = res.data.data
          this.paginationLength = res.data.totaldata
          this.dealsData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),
    exportToCSV() {
      // Convert the data to CSV format using json2csv library
      // let array = []
      // this.dealsData.map(item => {
      //   let data = {
      //   name_en:item.name_en,
      //   name_ar:item.name_ar,
      //   category: item.categories.map((item)=>{ return item.name}).join(", "),
      //   description_en:item.description_en,
      //   description_ar:item.description_ar,
      //   is_active:item.is_active,
      //   sequence:item.sequence,
      //   website:item.website,
      //   image:item.image,
      //   search_keyword_en:item.search_keyword_en,
      //   search_keyword_ar:item.search_keyword_ar,
      // }
      // array.push(data);
      // })
      const csvData = json2csv.parse(this.dealsData)
        ;
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
      link.download = 'Deals.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    closeModal() {
      this.$refs.modal.hide();
    },
    changeDrop(value){
      if(value == 'coupon'){
        this.brand = ''
      }else{
        this.coupon = ''
      }
    },
    AddDeal(){
      let data = {
        text : this.textEn,
        text_ar: this.textAr,
        short_description: this.shortDesEn,
        short_description_ar: this.shortDesAr,
        description: this.descriptionEn,
        description_ar: this.descriptionAr,
        client_id: this.brand ? this.brand.id : '',
        sequence: this.sequence,
        coupon_id: this.coupon? this.coupon.id : '',
        image_ar: this.imageAr,
        image: this.imageEn,
        redirection_url: this.customUrl,
        tag_name: this.tagName,
        button_text: this.TagTextEn,
        button_text_ar: this.TagTextAr,
        bg_color: this.bgColor,
        status:this.status ? 1 : 0
      }
      if (this.edit) {
        data = { ...{ id: this.editItem.id }, ...data }
      }
      if(this.sequence > this.sequence1){
        this.$swal({
                title: "Warning!!",
                text: `Sequence must be Lower than ${this.sequence1}`,
                icon: "warning",
                customClass: {
                  confirmButton: "btn btn-warning",
                },
                buttonsStyling: false,
              });
      }else{
      this.$refs.submitPrevent.validate().then((success) => {
        this.$http.post(`${this.baseURL}/deals/${this.edit ? 'update' : 'create'}`, data,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((json) => {
            if (json.data.status !== 201) {
              let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => {return `${item}:${json.data.data[item]}`}) : json.data.message
                  ? `${json.data.message}` 
                    : "Error"
              this.$swal({
                title: "Error!!",
                text: text,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              this.getClientStore()
              this.checkBoxIds = {}
              this.$refs['modal'].hide()
              this.$swal({
                title: "Submitted",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
      })
    }
    },
    handelChangeFilter() {
      if (this.filter == "All") {
        this.dealsData = this.dealsData1
      }
      else if (this.filter == "Active") {
        let data = []
        data = this.dealsData1.filter(item => item.is_active == 1)
        this.dealsData = data
      }
      else if (this.filter == "Inactive") {
        let data = []
        data = this.dealsData1.filter(item => item.is_active == 0)
        this.dealsData = data
      }
    },
    pagechange(){
      if(this.pageNo>(this.paginationLength/10)){
        this.pageNo = parseInt(this.paginationLength/10)
      }
      if(this.pageNo == 0){
        this.pageNo = 1
      }
        this.paginationStart = (this.pageNo*10)-10
        this.paginationEnd = (this.pageNo*10)
      let search = this.$store.state.searchData
          if (search) {
            this.searchPaginationHandelClick(search)
          } else {
            this.normalPaginationHandelClick(search)
          }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/deals/search`, {statusFilter:this.statusFilter, search: search, pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        this.dealsData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.dealsData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/deals/index`, {statusFilter:this.statusFilter, pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
        this.dealsData = res.data.data
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        // this.pageNo=res.data.page
        this.paginationLength = res.data.totaldata
        this.dealsData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
    },
    handelEdit(id) {
      this.addDealClick()
      this.popoverShow = false
      this.$http.post(`${this.baseURL}/deals/view/${id}`,{
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((json) => {
          this.editItem = json.data.data
            this.sequence = parseInt(this.editItem.sequence),
            this.sequence1 = 0
              this.$http.post(`${this.baseURL}/deals/get-sequence`).then((json) => {
                this.sequence1 = json.data.sequence - 1
              })
              this.textEn = this.editItem.text ,
              this.textAr = this.editItem.text_ar,
              this.shortDesEn = this.editItem.short_description;
              this.shortDesAr = this.editItem.short_description_ar;
              this.descriptionEn = this.editItem.description;
              this.descriptionAr = this.editItem.description_ar;
              this.brand = this.editItem.client_id;
              this.coupon = this.editItem.coupon_id;
              this.imageAr = this.editItem.image_ar;
              this.imageEn = this.editItem.image;
              this.customUrl = this.editItem.redirection_url;
              this.tagName = this.editItem.tag_name;
              this.TagTextEn = this.editItem.button_text;
              this.TagTextAr = this.editItem.button_text_ar;
              this.bgColor = this.editItem.bg_color;
            this.status = this.editItem.status ? true : false
            if(this.editItem.client_id){
           this.inlineRadio = 'Brand'
            }else{
              this.inlineRadio = 'Coupon'
            }
          this.edit = true
          this.$forceUpdate();
        })
      //  this.$router.push(`/roles/editrole/${id}`)
    },
    HandelClickMultipleEnableDisable() {
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0) {
        this.$http.post(`${this.baseURL}/deals/multi-enable-disable`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            this.$swal({
              title: "Updated",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Updated",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.getClientStore()
            this.popoverShow= false
            this.checkBoxIds = {}
            this.$forceUpdate()
          })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    HandelClickMultipleDelete() {
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0) {
        this.$http.post(`${this.baseURL}/deals/multi-delete`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            this.$swal({
              title: "Deleted",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Deleted",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.getClientStore()
            this.popoverShow= false
            this.checkBoxIds = {}
            this.$forceUpdate()
          })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    HandelClickMultipleDublicate() {
      let arr = []
      Object.keys(this.checkBoxIds).map((key) => {
        if (this.checkBoxIds[key] == true) {
          arr.push(key)
        }
      });
      if (arr.length > 0) {
        this.$http.post(`${this.baseURL}/deals/copy`, { ids: arr },
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true
          }).then((res) => {
            if(res.data.status == 201){
            this.$swal({
              title: "Success",
              text: res.data.message
                ? `${res.data.message}`
                : res.data.success
                  ? `${res.data.success}`
                  : "Success",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.getClientStore()
            this.popoverShow= false
            this.checkBoxIds = {}
            this.$forceUpdate()
          }else{
            let text = Object.keys(res.data.data).length > 0 ? Object.keys(res.data.data).map(item => {return `${item}:${res.data.data[item]}`}) : res.data.message
                  ? `${res.data.message}` 
                    : "Error"
              this.$swal({
                title: "Error!!",
                text: text,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
          }
          })
      } else {
        this.$swal({
          title: "Warning",
          text: "Select atleast one item",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      }
    },
    handelDelete(id) {
      this.$http.post(`${this.baseURL}/deals/delete`, { id: id },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.$swal({
            title: "Deleted",
            text: res.data.message
              ? `${res.data.message}`
              : res.data.success
                ? `${res.data.success}`
                : "Deleted",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getClientStore()
          this.onPopovcerOpen3(id)
          this.checkBoxIds = {}
          this.getClientStore()
          this.$forceUpdate()
        })
    },
    selectAllClick() {
      if (this.selectAllLabel == 'Deselect All') {
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
      } else {
        this.selectAllLabel = 'Deselect All'
        this.dealsData.map(item => {
          this.checkBoxIds[item.id] = true
        })
      }
      this.$forceUpdate()
    },
    CheckBoxHandelClick(id) {
      // this.checkBoxIds[id] = this.checkBoxIds[id] == undefined ? false : this.checkBoxIds[id]
      // this.checkBoxIds[id] = this.checkBoxIds[id] == true ? false : true
      let data = Object.keys(this.checkBoxIds).filter(item => this.checkBoxIds[item])
      if (data.length == this.dealsData.length) {
        this.selectAllLabel = 'Deselect All'
      } else {
        this.selectAllLabel = 'Select All'
      }
      this.$forceUpdate()
    },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    getdropData(){
      this.$http.post(`${this.baseURL}/client/get-all`).then((res) => {
          this.clientOption = res.data.data
          this.checkBoxIds = {}
        })
      this.$http.post(`${this.baseURL}/offers/get-for-deals/${this.$store.state.application.id}`).then((res) => {
          this.couponOption = res.data.data
          this.checkBoxIds = {}
        })
    },
    async getClientStore() {
      if (this.$store.state.application) {
        this.isLoading = true
        this.$http.post(`${this.baseURL}/deals/index`, {statusFilter:this.statusFilter, pageno: this.pageNo, app_id: this.$store.state.application.id }).then((res) => {
          this.dealsData = res.data.data
          this.paginationLength = res.data.totaldata
          this.dealsData1 = res.data.data
          this.isLoading = false
        })
      }
      // let data = await getdealsData();
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }
.popup-edge{
position: absolute;
width:50px;
height: 50px;
background-color: #ffffff;
right:0;
top:13px;
transform: rotate(45deg);
}
.popover-manual{
  position: absolute;
    right: 6.5cm !important;
    background-color: white;
    border-radius: 26px;
    padding: 5px 15px;
    left: auto;
    top:100px !important;
    z-index: 10000;
}
.popover-inner{
  display: flex;
  z-index: 1;
  position: relative;
}
.roundedImage{
  width:6.6cm;
  height:3cm;
  border-radius: 25px;
}
.btn-outline-success {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}
.btn-outline-danger{
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #ea5455;
  border: 1px solid #ea5455;
  border-radius: 4px;
}
.mb-half {
  margin-bottom: 5px;
}
.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  width: 200px;
  float: right;
}
.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}
.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}
.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}
.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}
.popover-body {
  border: none !important;
}
.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}

.custom-radio {
  border: 1px solid #d8e0f0;
  border-radius: 10px;
  color: #000 !important;
}

.vs-select__options__content {
  background-color: #ffffff !important;
}
.pop-btn {
  padding: 0 !important;
}
.modal-dialog {
  
}
.modal-body {
   padding: 0px 40px 40px 40px !important
}
.form-control {
  border-radius: 14px;
}
.modal-content {
  border-radius: 24px;
}
.modal-header {
  height:fit-content;
  border-radius: 24px;
  padding:10px
}
textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}
.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
</style>
